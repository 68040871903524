import React from 'react';
import { func, string } from 'prop-types';
import classnames from 'classnames';
import { Row, Col } from '@nutkit/component-grid';
import Panel from '@nutkit/component-panel';
import Select from '@nutkit/component-select';
import Link from '@nutkit/component-link';
import { Text, textSizes, Heading, headingLevels, tagNames } from '@nutkit/component-text';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import Thumbnail, { thumbnailSizes } from '@nutkit/component-thumbnail';
import Image from '@nutkit/component-image';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import { SupportArticles } from '@nm-utils-lib-web/routes';
import { getOrganisation } from '@nm-utils-lib-web/organisations';

import { getFullPNGImage } from '../../utils';
import { WRAPPER_OPTIONS } from '../../constants';

import styles from './HeaderPanel.module.scss';

const TRANSLATION_NAMESPACE = 'try.headerPanel';
const WRAPPER_TRANSLATION_NAMESPACE = 'try.common.wrapperTypes';
const optionWithImage = (text, image) => (
  <Inline space={inlineSpaces.SM}>
    <Thumbnail size={thumbnailSizes.XS}>
      <Image src={image} alt="" aria-hidden />
    </Thumbnail>
    {text}
  </Inline>
);

const HeaderPanel = ({ t, wrapperType, onChange }) => {
  const options = WRAPPER_OPTIONS[getOrganisation()].map(type => ({
    id: type,
    title: optionWithImage(t(`${WRAPPER_TRANSLATION_NAMESPACE}.${type}.title`), getFullPNGImage(type))
  }));
  const image = getFullPNGImage(wrapperType);
  const styleImages = {
    backgroundImage: `url(${image})`
  };
  const classes = classnames(styles.nmHeaderPanel, styles.nmHeaderPanelBackground);
  const selectedWrapper =
    WRAPPER_OPTIONS[getOrganisation()].find(wrapperOption => wrapperOption === wrapperType) || false;

  return (
    <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.LG}>
      <Panel className={classes} style={styleImages}>
        <Row>
          <Col md="8" lg="7">
            <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
            <Row>
              <Col md="8">
                <Select
                  id="product-select"
                  title={t(`${TRANSLATION_NAMESPACE}.selectLabel`)}
                  onSelect={onChange}
                  options={options}
                  value={wrapperType}
                />
              </Col>
            </Row>
            {selectedWrapper ? (
              <>
                <Text tagName={tagNames.PARAGRAPH} textSize={textSizes.SM}>
                  {t(`${WRAPPER_TRANSLATION_NAMESPACE}.${wrapperType}.description`)}
                </Text>
                <Link
                  href={t(`${WRAPPER_TRANSLATION_NAMESPACE}.${wrapperType}.learnMoreURL`)}
                  aria-label={t(`${WRAPPER_TRANSLATION_NAMESPACE}.${wrapperType}.learnMoreLinkLabel`)}
                  isExternal
                >
                  {t(`${WRAPPER_TRANSLATION_NAMESPACE}.${wrapperType}.learnMoreLinkLabel`)}
                </Link>
              </>
            ) : (
              <>
                <Text tagName={tagNames.SPAN} textSize={textSizes.SM}>
                  {t(`${TRANSLATION_NAMESPACE}.notSureText`)}
                </Text>
                <Link
                  href={SupportArticles.HELP_CHOOSE_PRODUCT}
                  aria-label={t(`${TRANSLATION_NAMESPACE}.link.ariaLabel`)}
                  isExternal
                >
                  {t(`${TRANSLATION_NAMESPACE}.link.text`)}
                </Link>
              </>
            )}
          </Col>
        </Row>
      </Panel>
    </Section>
  );
};

HeaderPanel.propTypes = {
  t: func.isRequired,
  wrapperType: string,
  onChange: func.isRequired
};

HeaderPanel.defaultProps = {
  wrapperType: 'products'
};

export default HeaderPanel;
